import React, { useState, lazy, Suspense, useEffect } from "react";
import {
    BrowserRouter as Router,
    Navigate,
    useNavigate
} from 'react-router-dom';
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { TermAndConditions } from "./pages/TermAndConditions";
import Layout from "./layouts/Layout";
import InvoiceNow from "./pages/InvoiceNow";
import { WaitingNew } from "./pages/WaitingNew";
import UnlockBusinessEfficiency from "./pages/UnlockBusinessEfficiency";
import {Unauthorization} from "./pages/Unauthorization";
import {Pricing} from "./pages/Pricing";
import Contact from "./pages/Contact";
import { ContactUs } from "./pages/ContactUs";
import EInvoicing from "./pages/MyInvoicing";

function MainRoute() {

    window.onpopstate = function () {
        window.history.go(-1);
    };

    const [baseName, setBaseName] = useState(process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : "");
    const mainPage = process.env.REACT_APP_MAIN_PAGE ? process.env.REACT_APP_MAIN_PAGE : "";
    async function getCountryCode() {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        console.log(data.country_code.toLowerCase())
        return data.country_code.toLowerCase(); // Returns 'sg', 'my', etc.
    }

    function LocationBasedRedirect() {
        const navigate = useNavigate();
        const [loading, setLoading] = useState(true);
        var pathName = window.location.pathname;
        if(pathName == "/sg" || pathName == "/my" || pathName == "/" || pathName == undefined) pathName = ""
        useEffect(() => {
          const fetchCountryCode = async () => {
            try {
              var currentCountryCode = localStorage.getItem("country");
              if(!currentCountryCode || currentCountryCode == ""){
                const countryCode = await getCountryCode();
                localStorage.setItem("country", countryCode)
                currentCountryCode = countryCode;
              }
              console.log("currentCountryCode",currentCountryCode,pathName)
              navigate(`/${currentCountryCode}${pathName}`);  // Redirect to /sg, /my, etc.
            } catch (error) {
              console.error("Failed to fetch country code", error);
              navigate('/');  // Default fallback to /us if unable to get country
            } finally {
              setLoading(false);
            }
          };
      
          fetchCountryCode();
        }, [navigate]);
      
        if (loading) {
          return <div>Loading...</div>; // Show loading while fetching location
        }
      
        return null; // Render nothing when redirecting
      }
      
    
    return (
        <Router basename={baseName}>
            <Routes>
                {/* <Route element={<Layout />}> */}
                {/* {mainPage == "" && ( */}
                <Route path="/"
                       element={<Suspense fallback={<div>Loading...</div>}><LocationBasedRedirect /></Suspense>}/>
                <Route path="/:country/"
                        element={<Suspense fallback={<div>Loading...</div>}><UnlockBusinessEfficiency/></Suspense>}/>
                {/* )} */}
                {/* {mainPage == "tnc" && ( */}
                <Route path="/:country/terms-conditions"
                       element={<Suspense fallback={<div>Loading...</div>}><TermAndConditions/></Suspense>}/>
                <Route path="/terms-conditions"
                      element={<Suspense fallback={<div>Loading...</div>}><TermAndConditions/></Suspense>}/>
                {/* )} */}
                {/* {mainPage == "pp" && ( */}
                <Route path="/:country/privacy-policy"
                       element={<Suspense fallback={<div>Loading...</div>}><PrivacyPolicy/></Suspense>}/>
                <Route path="/privacy-policy"
                      element={<Suspense fallback={<div>Loading...</div>}><PrivacyPolicy/></Suspense>}/>
                <Route path="/:country/contact"
                      element={<Suspense fallback={<div>Loading...</div>}><ContactUs/></Suspense>}/>
                <Route path="/contact"
                        element={<Suspense fallback={<div>Loading...</div>}><ContactUs/></Suspense>}/>
                {/* )} */}
                {/* </Route> */}
                <Route path="/invoicenow"
                       element={<Suspense fallback={<div>Loading...</div>}><LocationBasedRedirect/></Suspense>}/>
                <Route path="/:country/invoicenow"
                      element={<Suspense fallback={<div>Loading...</div>}><InvoiceNow/></Suspense>}/>
                <Route path="/e-invoicing"
                        element={<Suspense fallback={<div>Loading...</div>}><LocationBasedRedirect/></Suspense>}/>
                <Route path="/:country/e-invoicing"
                      element={<Suspense fallback={<div>Loading...</div>}><EInvoicing/></Suspense>}/>
                <Route path={"/timing"} element={<Suspense fallback={<div>Loading...</div>}><WaitingNew/></Suspense>}/>
                <Route path={"/404"}
                       element={<Suspense fallback={<div>Loading...</div>}><Unauthorization/></Suspense>}/>
                <Route path={"/:country/pricing"} element={<Suspense fallback={<div>Loading...</div>}><Pricing/></Suspense>}/>
                <Route path={"/pricing"} element={<Suspense fallback={<div>Loading...</div>}><LocationBasedRedirect /></Suspense>}/>
                <Route path={"/:country/contact/:subUrl"} element={<Suspense fallback={<div>Loading...</div>}><Contact/></Suspense>}/>
                <Route path={"/contact/:subUrl"} element={<Suspense fallback={<div>Loading...</div>}><Contact/></Suspense>}/>
            </Routes>
        </Router>
    );
}

export default MainRoute;
