import { useEffect } from "react";
import { AiFillCheckCircle, AiOutlineCheck, AiOutlineExclamation } from "react-icons/ai";
import { TextValue } from "../../translations";

interface ToastProps {
  /**
   * Visibility of toast
   */
  open: boolean;
  /**
   * Toast colour scheme
   */
  type?: string; //"success" | "Something went wrong, please try again.";
  /**
   * Message to display in the toast
   */
  message: string;
  /**
   * Callback to handle close event
   */
  handleClose: () => void;

  id: string;

  isFormatted?: boolean;
}

export const Toast = ({ open, type, message, handleClose, id, isFormatted }: ToastProps) => {
  // useEffect(() => {
  //   if (open) {
  //     setTimeout(() => {
  //       handleClose();
  //     }, 5000);
  //   }
  // }, [open]);
//   console.log("openopen", message)
//   useEffect(() => {
//     if (open == true && message.length > 0 && type) {
//       //localStorage.setItem('toastMessage', type+"]["+message);

//       // Dispatch custom event
//       const event = new Event('newToastMessage');
//       window.dispatchEvent(event);
//       handleClose();
//     }
//   }, [open]);
  return (
    <>
      {open ? (
        <div className="bottom-6 right-6 fixed min-w-[30%] z-[9999]">
          <div className="flex items-center justify-between max-w-sm p-4 bg-white border rounded-md shadow-md h-full delay-400 duration-500 ease-in-out transition-all transform translate-x-0">
            <div className="flex items-center">
              {type === "success" ? (
                <div className="bg-successColor flex items-center justify-center rounded-full">
                  <AiOutlineCheck className="w-full h-full text-white animate-pulse text-3xl p-1" />
                </div>
              ) : type === "warning" ? (
                <div className="bg-errorColor2 flex items-center justify-center rounded-full">
                  <AiOutlineExclamation className="w-full h-full text-white animate-pulse text-3xl p-1" />
                </div>
              ) : type === "created" ? (
                <div className="bg-statusCompleteText flex items-center justify-center rounded-full">
                  <AiOutlineCheck className="w-full h-full text-white animate-pulse text-3xl p-1" />
                </div>
              ) : (
                <div className="bg-errorColor3 flex items-center justify-center rounded-full">
                  <AiOutlineExclamation className="w-full h-full text-white animate-pulse text-3xl p-1" />
                </div>
              )}
              <p
                className={
                  "ml-3 text-sm font-bold " +
                  (type === "success"
                    ? "text-successColor"
                    : type === "warning" || type === "formatted"
                    ? "text-statusPendingText"
                    : type === "created"
                    ? "text-statusCompleteText"
                    : "text-errorColor")
                }
              >
                {type === "formatted"
                  ? message
                  : message.includes("\n")
                  ? message
                      .split("\n")
                      .map((msg: string) => (
                        <div>{TextValue(msg.charAt(0).toUpperCase() + msg.slice(1).toLowerCase())}</div>
                      ))
                  : TextValue(message.charAt(0).toUpperCase() + message.slice(1).toLowerCase())}
              </p>
            </div>
            <span
              onClick={() => {
                handleClose();
              }}
              className="inline-flex items-center cursor-pointer pl-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
