import {HomeLayout} from "../layouts/HomeLayout";
import {TextValue} from "../translations";
import axios from "axios";
import {apiLogin} from "../services";
import {useEffect, useState} from "react";
import Countdown from "../components/CountDown";
import CountdownV2 from "../components/CountDownV2";
import {Loader} from "../components/Loader";
import {colors} from "../constants/colourConfigurations";
import * as React from "react";

export const WaitingNew = () => {
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setIsLoading] = useState(false);
    const [initialSeconds, setInitialSecond] = useState(0)
    const handleGetDate=()=>{
        setIsLoading(true)
        apiLogin("constant/check-maintenance")
            .post({id: null, authenticated: false, body : {ID: 0}})
            .then(
                resp=>{
                    console.log("response data : ", resp.data)
                    if(resp.data && resp.data.result !== null){
                        let info = resp.data.result;
                        setEndDate(new Date(info.dtEnd));
                        const differenceInMillis = new Date().getTime() - new Date(info.dtEnd).getTime();
                        const seconds = Math.floor(differenceInMillis / 1000);
                        setInitialSecond(seconds)
                    }
                    setIsLoading(false)
                }
            ).catch(e=>{
            setIsLoading(false)
        })
    }
    useState(()=>{
        handleGetDate();
    })
    const mainContentDesktop=(
        <div className={`p-10 h-full w-full`}>
            {
                !loading &&
                <>
                    <div
                        className={`text-3xl sm:text-2xl lg:text-5xl pt-20 text-center text-primary font-bold`}>{TextValue("SITE UNDER MAINTENANCE")}</div>
                    <div className={`text-xl font-medium py-10 text-center`}>
                        {TextValue("Sorry for the inconvenience. To improve our service, we have momentarily shutdown our site.")}
                    </div>
                    <div className={`flex justify-center gap-4`}>
                        <div
                            className={`p-10 lg:w-2/5 md:w-1/2 sm:w-full bg-blue-500 text-white font-bold text-2xl md:text-4xl sm:text-3xl lg:text-5xl rounded-xl`}>
                            <CountdownV2 targetDate={endDate}/>
                            {/*<div className={``}>
                        <div>{endDate.toLocaleDateString('en-US', {month: "2-digit"})}</div>
                        <div className={`text-base text-gray-300 mt-2 text-center`}>{TextValue("MM")}</div>
                    </div>
                    <div className={``}>
                        <div>{endDate.toLocaleDateString('en-US', {day: "2-digit"})}</div>
                        <div className={`text-base text-gray-300 mt-2 text-center`}>{TextValue("DD")}</div>
                    </div>
                    <div className={``}>
                        <div>{endDate.getFullYear()}</div>
                        <div className={`text-base text-gray-300 mt-2 text-center`}>{TextValue("YYYY")}</div>
                    </div>*/}
                        </div>
                    </div>
                </>
            }
            {loading &&
                <div className={`my-[25%] flex justify-center content-center`}>
                    <Loader size={75} className={'flex'} visibility={true} colour={colors.secondary}
                            loadingText={''} />
                </div>
            }
            {/*<div className={`flex flex-wrap w-full justify-center items-center gap-6 my-10`}>
                <div className={`w-1/4`}>
                    <input type={"email"} placeholder={TextValue("Enter email address")} className={`ring-0 w-full px-4 py-2 rounded-lg border border-solid border-gray-400 text-base`}/>
                </div>
                <div>
                    <button
                        type="button"
                        className="font-medium text-white bg-primary rounded-lg hover:ring-0 hover:outline-none hover:bg-opacity-70 active:bg-opacity-50 font-medium rounded-xl text-sm px-4 py-2 text-center mr-3 md:mr-0 ">
                        Get Notified!
                    </button>
                </div>
            </div>*/}
        </div>
    )
    return (
        <HomeLayout isLoading={false} showHeader={true} showFooter={false} mainContent={mainContentDesktop}
                    currentPage={"Timing"}/>
    )
}