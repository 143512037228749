import * as CryptoJS from "crypto-js";

const ENCRYPT_KEY: string = process.env.REACT_APP_ENCRYPT_KEY!;
const ENCRYPT_PHRASE: string = process.env.REACT_APP_ENCRYPT_PHRASE!;
const baseName: string = "";

const encrypt = (input: any): string => {
  //console.log(input);
  const key = CryptoJS.enc.Utf8.parse(ENCRYPT_KEY);

  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(input)), ENCRYPT_PHRASE, key);

  const e64 = CryptoJS.enc.Base64.parse(encrypted.toString());
  const eHex = e64.toString(CryptoJS.enc.Hex);

  return eHex;
};

const decrypt = (input: string): any => {
  //console.log(input);
  try {
  if (input && input.length > 0) {
    const key = CryptoJS.enc.Utf8.parse(ENCRYPT_KEY);

    const reb64 = CryptoJS.enc.Hex.parse(input);
    const bytes = reb64.toString(CryptoJS.enc.Base64);

    const decrypted = CryptoJS.AES.decrypt(bytes, ENCRYPT_PHRASE, key);

    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  } 
  // else window.location.href = baseName;
  } catch (e) {
    window.location.href = baseName;
  }
};

export const Crypto = {
  encrypt,
  decrypt,
};
