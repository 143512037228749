import * as React from "react";
import { useEffect, useState, useContext } from "react";
import Header from "../components/Header";
import { Loader } from "../components/Loader";
import Footer from "../components/Footer";
import { colors } from "../constants/colourConfigurations";
import "./ComponentStyles.css";
import { CookieService } from "../utils/cookieservice";

interface Home_DesktopProps {
  mainContent: React.ReactNode;
  currentPage?: string;
  showOverlay?: boolean;
  overlayContent?: React.ReactNode;
  overlayStyleClass?: string;
  overlayWidth?: string;
  overlayHeight?: string;
  handleOverlayExit?: () => void;
  isLoading?: boolean;
  loadingMessage?: string;
  showHeader?: boolean;
  showFooter?: boolean;
}

export const HomeLayout = ({
  mainContent,
  currentPage,
  showOverlay,
  overlayContent,
  overlayStyleClass,
  overlayHeight,
  overlayWidth,
  handleOverlayExit = () => {},
  isLoading = false,
  loadingMessage,
  showHeader = true,
  showFooter = true,
}: Home_DesktopProps) => {
  const baseName = process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : "";

  useEffect(() => {
    window.scrollTo(0, 0);
    handleLogout();
  }, []);

  const handleLogout = async () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    const rememberMeValue = localStorage.getItem("rememberMe");
    const userName = localStorage.getItem("u");
    const password = localStorage.getItem("c");
    const country = localStorage.getItem("country");
    CookieService.remove("x-token");
    const remove = async () => {
      localStorage.clear();
      localStorage.removeItem("version");
      CookieService.removeAll();
      localStorage.setItem("rememberMe", rememberMeValue ? rememberMeValue : "");
      localStorage.setItem("u", userName ? userName : "");
      localStorage.setItem("c", password ? password : "");
      localStorage.setItem("country", country ? country : "");
      //window.location.reload();
    };
    await remove();
  };

  return (
    <div
      style={currentPage === "Timing" ? {} : {}}
      className={`flex w-full bg-white flex-col items-center justify-between font-bevietnampro ${
        currentPage === "Timing" && "countdown-container"
      }`}
    >
      {showHeader && <Header currentPage={currentPage} />}
      {isLoading === false && mainContent}
      {isLoading == true && (
        <div className={"w-full grid grid-cols-1 content-center "}>
          <div className={"flex w-full justify-center "}>
            <Loader size={75} className={"flex"} visibility={true} colour={colors.secondary} loadingText={""} />
          </div>
          <div className="flex w-full justify-center pt-2 font-bold text-primary">{loadingMessage}</div>
        </div>
      )}
      {showFooter && <Footer />}
    </div>
  );
};
