import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface ReCaptchaComponentProps {
  onVerify: (token: string | null) => void;
}

const ReCaptchaComponent: React.FC<ReCaptchaComponentProps> = ({ onVerify }) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
    
  const handleRecaptchaChange = (token: string | null) => {
    onVerify(token);
  };
  
  return (
    <div>
      <ReCAPTCHA
        ref={recaptchaRef}
        // sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY? process.env.REACT_APP_RECAPTCHA_SITE_KEY: ""}
        onChange={handleRecaptchaChange}
        sitekey='6LdMDhcqAAAAAJxKAlgimQIQr3tOAo4C2QkOjA33'
      />
    </div>
  );
};

export default ReCaptchaComponent;
