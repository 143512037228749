import axios from "axios";
import { IHttpInput } from "../../types";
import { Crypto } from "../../utils/encryption";
import { handleResponse, handleError } from "../../utils/response";

//init token from cookie here
const baseURL = process.env.REACT_APP_API;

let instance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "multipart/form-data",
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    Expires: "0",
  },
});
const baseName = process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : '';

const getAll = async <T>(url: string, input: IHttpInput) => {
  let passToken = { Authorization: `` };
  return axios
    .get<T>(`${baseURL}${url}`, {
      headers: passToken,
    })
    .then(handleResponse)
    .catch((e) => {
      if (e.response.status == 401) {
        // alert("Session has expired!");
        localStorage.clear();
        window.history.pushState({}, "Login", baseName);
        window.history.go();
      }
      handleError(e);
    });
};

const getSingle = async <T>(url: string, input: IHttpInput) => {
  let passToken = { Authorization: `` };
  var encryptInput = "";
  if (input.id != null) {
    encryptInput = "/" + Crypto.encrypt(input.id);
  }
  return axios
    .get<T>(`${baseURL}${url}${encryptInput}`, {
      headers: passToken,
    })
    .then(handleResponse)
    .catch((e) => {
      if (e.response.status == 401) {
        // alert("Session has expired!");
        localStorage.clear();
        window.history.pushState({}, "Login", baseName);
        window.history.go();
      }
      handleError(e);
    });
};
const post = async <T>(url: string, input: IHttpInput) => {
  let passToken = { Authorization: `` };
  return axios
    .post<T>(`${baseURL}${url}`, Crypto.encrypt(input.body), {
      headers: passToken,
    })
    .then(handleResponse)
    .catch((e) => {
      if (e.response.status == 401) {
        // alert("Session has expired!");
        localStorage.clear();
        window.history.pushState({}, "Login", baseName);
        window.history.go();
      } else if (e.response.status == 400) {
        return Promise.reject(e);
      }
      handleError(e);
    });
};

const put = async <T>(url: string, input: IHttpInput) => {
  let passToken = { Authorization: `` };
  return axios
    .put<T>(`${baseURL}${url}`, Crypto.encrypt(input.body), {
      headers: passToken,
    })
    .then(handleResponse)
    .catch(handleError);
};

const remove = async <T>(url: string, input: IHttpInput) => {
  let passToken = { Authorization: `` };

  return axios
    .delete<T>(`${baseURL}${url}/${Crypto.encrypt(input.id)}`)
    .then(handleResponse)
    .catch(handleError);
};

export const apiProvider = {
  getAll,
  getSingle,
  post,
  put,
  remove,
};
