import React, { useState, useEffect } from 'react';
import {TextValue} from "../translations";

interface CountdownProps {
    targetDate: Date;
    handleCallBack?: any;
}

const CountdownV2 = ({ targetDate, handleCallBack=()=>{} }:CountdownProps) => {
    const calculateTimeLeft = () => {
        const difference = +targetDate - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }else{
            timeLeft = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
            handleCallBack()
        }, 1000);

        return () => clearTimeout(timer);
    });
    const { hours, minutes, seconds, days } = timeLeft;
    const formattedTime = `${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`;

    return (
        <div className={`flex justify-center gap-6 `}>
            <div>
                <div>{days < 10 ? '0' + days : days}</div>
                <div
                    className={`text-base text-gray-300 mt-2 text-center`}>{TextValue("DD")}</div>
            </div>
            <div className={`flex gap-4`}>
                <div className={``}>
                    <div>{hours < 10 ? '0' + hours : hours}</div>
                    <div className={`text-base text-gray-300 mt-2 text-center`}>{TextValue("HH")}</div>
                </div>
                <div>{":"}</div>
                <div className={``}>
                    <div>{minutes < 10 ? '0' + minutes : minutes}</div>
                    <div className={`text-base text-gray-300 mt-2 text-center`}>{TextValue("MM")}</div>
                </div>
                <div>{":"}</div>
                <div className={``}>
                    <div>{seconds < 10 ? '0' + seconds : seconds}</div>
                    <div className={`text-base text-gray-300 mt-2 text-center`}>{TextValue("SS")}</div>
                </div>
            </div>
        </div>
    );
};

export default CountdownV2;
